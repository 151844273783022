<template>
  <!-- Name und Adresse Edit Modal -->
  <div
    class="modal fade"
    id="bankverbindungEditModal"
    tabindex="-1"
    aria-labelledby="bankverbindungEditModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="bankverbindungEditModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="bankverbindungEditModalLabel">
            Edit bank details
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body" v-if="loading">
          <LoadingSpinner />
        </div>

        <div class="modal-body" v-if="!loading">
          <div class="mb-5">
            <label class="form-label" for="iban">IBAN</label>
            <div id="ibanInputFields" class="input-group">
              <input v-model="iban0" type="text" class="form-control ibanInputs text-uppercase" >
              <input v-model="iban1" type="text" class="form-control ibanInputs text-uppercase" maxlength="4" :disabled="iban0.length < 4 && iban2.length == 0 ? true : false">
              <input v-model="iban2" type="text" class="form-control ibanInputs text-uppercase" maxlength="4" :disabled="iban1.length < 4 && iban3.length == 0 ? true : false">
              <input v-model="iban3" type="text" class="form-control ibanInputs text-uppercase" maxlength="4" :disabled="iban2.length < 4 && iban4.length == 0 ? true : false">
              <input v-model="iban4" type="text" class="form-control ibanInputs text-uppercase" maxlength="4" :disabled="iban3.length < 4 && iban5.length == 0 ? true : false">
              <input v-model="iban5" type="text" class="form-control ibanInputs text-uppercase" :maxlength="de_iban ? 2 : 4" :disabled="iban4.length < 4 && iban6.length == 0 ? true : false">
              <input v-model="iban6" type="text" class="form-control ibanInputs text-uppercase" maxlength="4" :disabled="(iban5.length < 4 && iban7.length == 0) || de_iban ? true : false">
              <input v-model="iban7" type="text" class="form-control ibanInputs text-uppercase" maxlength="4" :disabled="(iban6.length < 4 && iban8.length == 0) || de_iban ? true : false">
              <input v-model="iban8" type="text" class="form-control ibanInputs text-uppercase" maxlength="2" :disabled="iban7.length < 4 || de_iban ? true : false">
              <button class="btn btn-outline-secondary" @click="clearIbanInput()" :disabled="privateIban.length == 0">
                <font-awesome-icon icon="trash" />
              </button>
            </div>
            <div class="alert alert-danger py-1 mt-1" role="alert" v-if="ibanError">
              The IBAN is invalid.
            </div>
          </div>


          <!-- Bankname
          <div class="mb-3">
            <label class="form-label" for="bankname">Bankname</label>
            <input
              type="text"
              class="form-control"
              id="bankname"
              placeholder="Bankname wird ermittelt..."
              v-model="bankname"
              disabled readonly
            />
          </div> -->

          <div v-if="!de_iban" class="mb-3">
            <label class="form-label">BIC</label>
            <input
              type="text"
              class="form-control"
              v-model="bic"
            />
          </div>

          <hr>

          <p>
            <strong>Email address used to confirm the changes and questions from the HR department.</strong>
          </p>

          <div class="mb-3">
            <label class="form-label">Response E-Mail Address</label>
            <input
              type="text"
              class="form-control"
              v-model="responseEmailAdresse"
            />
          </div>

          <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
            <pre>{{errorMessage}}</pre>
          </div>

        </div>  

        <div class="modal-footer" v-if="!loading">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal()"
          >
            Cancel
          </button>
          
          <button class="btn btn-primary" @click="saveChanges()" :disabled="ibanError">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { BankverbindungEditDataRequest } from "@/models/Personal/PersonalModels";

export default defineComponent ({
  name: "BankverbindungEditModalEN",
  components: {
    LoadingSpinner
  },

  setup () {
    const store = useStore();
    const loading = computed(() => store.getters.status.personalLoading)
    const status = computed(() => store.getters.status)

    const personaldaten = computed(() => {
      setIban(store.getters.personaldaten.iban)
      return store.getters.personaldaten
    });

    const setIban = (ibanString: string) => {
      let iban = ibanString.replace(/\s/g, '').toUpperCase();

      var ibanSplit = iban.match(/.{1,4}/g)
      if (ibanSplit != null) {
        data.iban0 = ibanSplit[0] || "";
        data.iban1 = ibanSplit[1] || "";
        data.iban2 = ibanSplit[2] || "";
        data.iban3 = ibanSplit[3] || "";
        data.iban4 = ibanSplit[4] || "";
        data.iban5 = ibanSplit[5] || "";
        data.iban6 = ibanSplit[6] || "";
        data.iban7 = ibanSplit[7] || "";
        data.iban8 = ibanSplit[8] || "";
      }
    }

    const privateIban = computed(() => {
      var ibanString = (data.iban0 + data.iban1 + data.iban2 + data.iban3 + data.iban4 + data.iban5 + data.iban6 + data.iban7 + data.iban8).toUpperCase()
      var iban = ibanString.replace(/\s/g, '').toUpperCase();

      if (iban.length > 2 && !ibanRegex.test(iban)) {
        ibanError.value = true;
      }
      else {
        ibanError.value = false;
      }

      de_iban.value = iban.length < 2 || iban.startsWith("DE")
      if (de_iban.value) {
        data.iban5 = data.iban5.slice(0,2)
        data.iban6 = "";
        data.iban7 = "";
        data.iban8 = "";
      }

      return iban
    });

    const data = reactive({
      iban0: "",
      iban1: "",
      iban2: "",
      iban3: "",
      iban4: "",
      iban5: "",
      iban6: "",
      iban7: "",
      iban8: "",
    })
    

    const ibanRegex = /^[A-Z]{2}[0-9]{1,32}$/;
    const ibanError = ref(false)
    const de_iban = ref(false)

    const state = reactive({
      bankname: personaldaten.value.bankname,
      bic: personaldaten.value.bic,
      responseEmailAdresse: personaldaten.value.email,
      postfachartid: 1
    })

    let errorMessage = ref("");

    const resetForm = () => {
      state.bankname = personaldaten.value.bankname;
      state.bic = personaldaten.value.bic;
      state.responseEmailAdresse = personaldaten.value.email;
      state.postfachartid = 1;
      errorMessage.value = "";
    }

    const bankverbindungEditModalRef = ref();
    const closeModal = () =>  {
      resetForm();
      setIban(personaldaten.value.iban)
      Modal.getInstance(bankverbindungEditModalRef.value)?.hide();
    }
    const showModal = () => {
      if (bankverbindungEditModalRef.value) {
        new Modal(bankverbindungEditModalRef.value).show();
      }
    }

    return {
      store,
      loading,
      status,
      personaldaten,
      ...toRefs(state),
      errorMessage,
      bankverbindungEditModalRef,
      closeModal,
      showModal,
      ibanError,
      de_iban,
      privateIban,
      ...toRefs(data),
      setIban
    }
  },

  data () {
    return {
      wrapper: "",
      el: ""
    }
  },

  methods: {
    async saveChanges () {
      this.errorMessage = "";

      const data: BankverbindungEditDataRequest = {
        bankname: "",
        iban: this.privateIban,
        bic: this.de_iban ? "" : this.bic,
        responseEmailAdresse: this.responseEmailAdresse,
        postfachartid: 1
      }


      this.store.dispatch(PersonalActionTypes.UpdateBankverbindung, data)
        .then(() => {
          
          if (this.status.personalErrorMsg != "") {
            this.errorMessage = this.status.personalErrorMsg;
          }
          else {
            this.closeModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
          }
        });
    },

    clearIbanInput () {
      this.iban0 = "";
      this.iban1 = "";
      this.iban2 = "";
      this.iban3 = "";
      this.iban4 = "";
      this.iban5 = "";
      this.iban6 = "";
      this.iban7 = "";
      this.iban8 = "";
    },

    autoSelectText(e: FocusEvent) {
      let el = e.target as HTMLInputElement;
      el.select();
    },

    pasteEvent(e: ClipboardEvent) {
      e.stopPropagation();
      e.preventDefault();

      // Get pasted data via clipboard API
      var pastedData = e.clipboardData?.getData('Text');

      this.setIban(pastedData || "");
    },

    checkInputFirst(e: KeyboardEvent) {
      const regex = /^[A-Za-z0-9]+$/;
      if (!regex.test(e.key)) {
        e.preventDefault();
      }
    },

    checkInputNumber(e: KeyboardEvent) {
      const regex = /^[0-9]+$/;
      if (!regex.test(e.key)) {
        e.preventDefault();
      }
    },

    setIbanInputEventListener () {
      const wrapper = document.getElementById("ibanInputFields") as HTMLElement | null
      const el = wrapper?.querySelectorAll(".ibanInputs") as NodeListOf<HTMLInputElement>

      Array.prototype.forEach.call(el, (e: HTMLInputElement, index: number) => {
        e.addEventListener("focus", this.autoSelectText);
        e.addEventListener("paste", this.pasteEvent);
        
        if (index == 0) {
          e.addEventListener("keydown", this.checkInputFirst);
        }
        else {
          e.addEventListener("keydown", this.checkInputNumber);
        }

        // Auto jump to next input if 4 chars in input
        e.addEventListener("keyup", (e: KeyboardEvent) => {
          if (!(e.key == "ArrowLeft" || e.key == "ArrowRight")) {
            const target = e.target as HTMLInputElement | null;
            
            if (target) {
              const maxlength = 4;
              const inputLength = target.value.length;
              
              if (!(target.selectionStart == 0 && target.selectionEnd == target.value.length)) {
                if (maxlength === inputLength && index < (el.length - 1)) {
                  el[index + 1].focus();
                }
              }
            }
          }
        });
      })
    }
  },

  mounted () {
    this.setIbanInputEventListener()
  }

})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>