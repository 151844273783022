import { RootState } from '@/store';

import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";

import { Actions as ArchivActions, ActionTypes as ArchivActionTypes} from "../Archiv/actions"
import { Actions as NachrichtenActions, ActionTypes as NachrichtenActionTypes} from "../Nachrichten/actions"

import { State } from "./state";
import router from "@/router";

import { Status, KontaktinformationenEditDataRequest, LoginExtPersnrRequest, LoginGuidRequest, LoginRequest, NameUndAdresseEditDataRequest, BankverbindungEditDataRequest, Personaldaten, User, GetProfilbildRequestData, LoginAzureRequest, AzureConfigEinstellung, Archivdata, SendDocAsMailRequest, KrankenkasseEditDataRequest, RefreshLoginRequest, LogoutRequest, MultipleDocsRequest } from "@/models/Personal/PersonalModels";
import { ArchivDownloadCompRequest, Dokumentart, ArchivComponenteFile} from "@/models/Archiv/ArchivModels";

import ApiService from "@/services/ApiService";
import ArchivApiService from "@/services/ArchivApiService";


export enum ActionTypes {
  Login = "LOGIN",
  TerminalLogin = "TERMINALLOGIN",
  LoginGuid = "LOGINGUID",
  LoginExtPersnr = "LOGINEXTPERSNR",
  LoginAzure = "LOGINAZURE",
  RefreshLogin = "REFRESHLOGIN",
  Logout = "LOGOUT",

  GetPersonaldaten = "GETPERSONALDATEN",
  GetArchivdaten = "GETARCHIVDATEN",
  UpdatePersonaldatenKontaktinformationen = "UPDATEPERSONALDATENKONTAKTINFORMATIONEN",
  UpdatePersonaldatenNameUndAdresse = "UPDATEPERSONALDATENNAMEUNDADRESSE",
  UpdatePersonaldatenNameUndAdresseTerminal = "UPDATEPERSONALDATENNAMEUNDADRESSETERMINAL",
  UpdateBankverbindung = "UPDATEBANKVERBINDUNG",
  UpdateKrankenkasse = "UPDATEKRANKENKASSE",
  GetProfilbild = "GETPROFILBILD",
  GetAzureLoginConfig = "GETAZURELOGINCONFIG",
  SendDocAsMail = "SENDDOCASMAIL",
  SendMultipleDocsAsMail = "SENDMULTIPLEDOCSASMAIL",
  GetMultipleDocsAsZip = "GETMULTIPLEDOCSASZIP",

  SetStatus = "SETSTATUS",
  ToggleSidebar = "TOGGLESIDEBAR",
  ToggleSidebarRechts = "TOGGLESIDEBARRECHTS"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.Login](context: ActionArguments, loginRequest: LoginRequest): Promise<void>
  [ActionTypes.TerminalLogin](context: ActionArguments, loginRequest: LoginRequest): Promise<void>
  [ActionTypes.LoginGuid](context: ActionArguments, loginGuidRequest: LoginGuidRequest): Promise<void>
  [ActionTypes.LoginExtPersnr](context: ActionArguments, loginExtPersnrRequest: LoginExtPersnrRequest): Promise<void>
  [ActionTypes.LoginAzure](context: ActionArguments, azureLogin: LoginAzureRequest): Promise<void>
  [ActionTypes.RefreshLogin](context: ActionArguments): Promise<void>
  [ActionTypes.Logout](context: ActionArguments): Promise<void>

  [ActionTypes.GetPersonaldaten](context: ActionArguments): Promise<void>
  [ActionTypes.GetArchivdaten](context: ActionArguments): Promise<void>
  [ActionTypes.UpdatePersonaldatenKontaktinformationen](context: ActionArguments, data: KontaktinformationenEditDataRequest): Promise<void>
  [ActionTypes.UpdatePersonaldatenNameUndAdresse](context: ActionArguments, data: NameUndAdresseEditDataRequest): Promise<void>
  [ActionTypes.UpdatePersonaldatenNameUndAdresseTerminal](context: ActionArguments, data: NameUndAdresseEditDataRequest): Promise<void>
  [ActionTypes.UpdateBankverbindung](context: ActionArguments, data: BankverbindungEditDataRequest): Promise<void>
  [ActionTypes.UpdateKrankenkasse](context: ActionArguments, data: KrankenkasseEditDataRequest): Promise<void>
  [ActionTypes.GetProfilbild](context: ActionArguments, data: GetProfilbildRequestData): void
  [ActionTypes.GetAzureLoginConfig](context: ActionArguments): void
  [ActionTypes.SendDocAsMail](context: ActionArguments, data: SendDocAsMailRequest): Promise<void>
  [ActionTypes.SendMultipleDocsAsMail](context: ActionArguments, data: MultipleDocsRequest): Promise<void>
  [ActionTypes.GetMultipleDocsAsZip](context: ActionArguments, data: MultipleDocsRequest): Promise<void>

  [ActionTypes.SetStatus](context: ActionArguments, status: Status): void
  [ActionTypes.ToggleSidebar](context: ActionArguments): void
  [ActionTypes.ToggleSidebarRechts](context: ActionArguments): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.SetStatus]({commit}, status) {
    commit(MutationTypes.SetStatus, status);
  },

  [ActionTypes.ToggleSidebar]({commit, state}) {
    const status = state.status
    status.sidebarCollapsed = !status.sidebarCollapsed
    commit(MutationTypes.SetStatus, status);
  },

  [ActionTypes.ToggleSidebarRechts]({commit, state}) {
    const status = state.status
    status.sidebarRechtsCollapsed = !status.sidebarRechtsCollapsed
    commit(MutationTypes.SetStatus, status);
  },

  //#region Auth
  async [ActionTypes.Login]({commit, getters}, loginRequest: LoginRequest) {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalLoginLoading = true;
    commit(MutationTypes.SetStatus, status);

    ApiService.login(loginRequest)
      .then(res => {
        console.log("Login erfolgreich");
        status.personalLoginLoading = false;
        status.personalErrorMsg = "";

        commit(MutationTypes.SetStatus, status);

        const user: User = res.data;

        commit(MutationTypes.loginSuccess, user);
        sessionStorage.setItem('user', JSON.stringify(user));

        router.push("/");
      })
      .catch(error => {
        status.personalLoginLoading = false;
        status.personalErrorMsg = error.response.data.title;
        commit(MutationTypes.SetStatus, status);
        sessionStorage.removeItem('user');
      })
  },

  async [ActionTypes.LoginAzure]({commit, getters}, azureLogin) {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalLoginLoading = true;
    commit(MutationTypes.SetStatus, status);
    commit(MutationTypes.SetAzureUsername, azureLogin.username)
    sessionStorage.setItem('azureusername', JSON.stringify(azureLogin.username));

    ApiService.loginAzure(azureLogin.idToken)
      .then(res => {
        status.azureLogin = true;
        status.personalLoginLoading = false;
        status.personalErrorMsg = "";
        commit(MutationTypes.SetStatus, status);

        const user: User = res.data;
        commit(MutationTypes.loginSuccess, user);
        sessionStorage.setItem('user', JSON.stringify(user));

        router.push("/");
      })
      .catch(error => {
        status.personalLoginLoading = false;
        status.personalErrorMsg = error.response.data.title;
        commit(MutationTypes.SetStatus, status);
        sessionStorage.removeItem('user');
      })
  },

  async [ActionTypes.TerminalLogin]({commit, getters}, loginRequest) {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalLoginLoading = true;
    commit(MutationTypes.SetStatus, status);
    sessionStorage.removeItem('user');

    ApiService.login(loginRequest)
      .then(res => {
        status.personalErrorMsg = "";
        status.personalLoginLoading = false;
        commit(MutationTypes.SetStatus, status);

        const user: User = res.data;

        commit(MutationTypes.loginSuccess, user);
        sessionStorage.setItem('user', JSON.stringify(user));

        router.push("/");
      })
      .catch(error => {
        status.personalLoginLoading = false;
        status.personalErrorMsg = error.response.data.title;
        commit(MutationTypes.SetStatus, status);
        sessionStorage.removeItem('user');
      })
  },

  async [ActionTypes.LoginGuid]({commit, getters}, loginGuidRequest: LoginGuidRequest) {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalLoginLoading = true;
    commit(MutationTypes.SetStatus, status);

    ApiService.loginGuid(loginGuidRequest)
      .then(res => {
        status.personalErrorMsg = "";
        status.personalLoginLoading = false;
        commit(MutationTypes.SetStatus, status);

        const user: User = res.data;

        commit(MutationTypes.loginSuccess, user);
        sessionStorage.setItem('user', JSON.stringify(user));

        router.push("/");
      })
      .catch(error => {
        status.personalLoginLoading = false;
        status.personalErrorMsg = error.response.data.title;
        commit(MutationTypes.SetStatus, status);
        sessionStorage.removeItem('user');
      })
  },

  async [ActionTypes.LoginExtPersnr]({commit, getters}, loginExtPersnrRequest: LoginExtPersnrRequest) {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalLoginLoading = true;
    commit(MutationTypes.SetStatus, status);

    ApiService.loginExtPersnr(loginExtPersnrRequest)
      .then(res => {
        status.personalErrorMsg = "";
        status.personalLoginLoading = false;
        commit(MutationTypes.SetStatus, status);

        const user: User = res.data;

        commit(MutationTypes.loginSuccess, user);
        sessionStorage.setItem('user', JSON.stringify(user));

        router.push("/");
      })
      .catch(error => {
        status.personalLoginLoading = false;
        status.personalErrorMsg = error.response.data.title;
        commit(MutationTypes.SetStatus, status);
        sessionStorage.removeItem('user');
      })
  },

  async [ActionTypes.RefreshLogin]({commit, dispatch, rootGetters}) {
    const request = new RefreshLoginRequest(rootGetters.user.refreshToken);
    const res = await ApiService.refreshLogin(request);

    const result: User = res.data;
    commit(MutationTypes.RefreshLoginSuccess, result)
    sessionStorage.setItem('user', JSON.stringify(result));

    return
  },

  async [ActionTypes.Logout]({commit, dispatch, getters, rootGetters}) {
    const status = getters.status;
    status.personalLogoutLoading = true;
    status.azureLogin = false;
    commit(MutationTypes.SetStatus, status);

    const request = new LogoutRequest(rootGetters.user.refreshToken);
    return ApiService.logout(request)
    .then(async () => {
      await dispatch(ArchivActionTypes.ArchivLogout, undefined)
      
      let terminalVersion = rootGetters.terminalVersion
      let msalConfig = rootGetters.msalConfig
      sessionStorage.clear();
      sessionStorage.setItem("terminalVersion",  JSON.stringify(terminalVersion))
      sessionStorage.setItem("msalconfig",  JSON.stringify(msalConfig))
      
      let language = rootGetters.language
      localStorage.clear();
      localStorage.setItem("language",  JSON.stringify(language))
      
      commit(MutationTypes.ClearState, undefined);
      dispatch(NachrichtenActionTypes.ClearNachrichtenState, undefined)
      commit(MutationTypes.logout, undefined);
    })
    .catch(async () => {
      await dispatch(ArchivActionTypes.ArchivLogout, undefined)
      
      let terminalVersion = rootGetters.terminalVersion
      let msalConfig = rootGetters.msalConfig
      sessionStorage.clear();
      sessionStorage.setItem("terminalVersion",  JSON.stringify(terminalVersion))
      sessionStorage.setItem("msalconfig",  JSON.stringify(msalConfig))
      
      let language = rootGetters.language
      localStorage.clear();
      localStorage.setItem("language",  JSON.stringify(language))
      
      commit(MutationTypes.ClearState, undefined);
      dispatch(NachrichtenActionTypes.ClearNachrichtenState, undefined)
      commit(MutationTypes.logout, undefined);
    })

    return
  },
  //#endregion

  async [ActionTypes.GetPersonaldaten]({commit, getters}) {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.getPersonaldatenLoading = true;
    commit(MutationTypes.SetStatus, status);

    return ApiService.getPersonaldatenByGuid()
      .then(res => {
        status.personalErrorMsg = "";
        status.getPersonaldatenLoading = false;
        commit(MutationTypes.SetStatus, status);

        const personaldaten: Personaldaten = res.data;
        commit(MutationTypes.PersonaldatenSuccess, personaldaten);
        sessionStorage.setItem('personaldaten', JSON.stringify(personaldaten));
      })
      .catch(error => {
        status.getPersonaldatenLoading = false;
        status.personalErrorMsg = error.response.data.message;
        commit(MutationTypes.SetStatus, status);
        sessionStorage.removeItem('personaldaten');
      })
  },

  [ActionTypes.GetArchivdaten]({commit}) {
    return ApiService.getArchivdatenByGuid()
      .then(res => {
        const archivdaten: Archivdata = res.data
        commit(MutationTypes.ArchivdatenSuccess, archivdaten);
      })
  },

  async [ActionTypes.UpdatePersonaldatenKontaktinformationen]( {commit, getters}, data: KontaktinformationenEditDataRequest): Promise<void> {
    const status = getters.status;
    status.personalErrorMsg = "";
    status.personalLoading = true;
    commit(MutationTypes.SetStatus, status);

    return ApiService.putPersonaldatenKontaktinformationen(data)
      .then(res => {
        const personaldaten: Personaldaten = res.data;

        commit(MutationTypes.PersonaldatenSuccess, personaldaten);
        sessionStorage.setItem('personaldaten', JSON.stringify(personaldaten));
      })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          status.personalErrorMsg = "Es ist etwas schief gelaufen, bitte probieren Sie es später erneut.";
          commit(MutationTypes.RequestFailure, "Es ist etwas schief gelaufen, bitte probieren Sie es später erneut.");
        }
        
        else {
          status.personalErrorMsg = error.response.data.message;
          commit(MutationTypes.RequestFailure, error.response.data.message);
          sessionStorage.removeItem('personaldaten');
        } 
      })
      .finally(() => {
        status.personalLoading = false;
        commit(MutationTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdatePersonaldatenNameUndAdresse]( {commit, getters}, data: NameUndAdresseEditDataRequest): Promise<void> {
    const status = getters.status;

    status.personalLoading = true;
    status.personalErrorMsg = "";
    commit(MutationTypes.SetStatus, status);

    return ApiService.putPersonaldatenNameUndAdresse(data)
      .then(res => {
        const personaldaten: Personaldaten = res.data;

        commit(MutationTypes.PersonaldatenSuccess, personaldaten);
        sessionStorage.setItem('personaldaten', JSON.stringify(personaldaten));
      })
      .catch(error => {
        if (error.code == "ERR_NETWORK") {
          commit(MutationTypes.RequestFailure, error.code + "\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.");
          status.personalErrorMsg = error.code + "\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.";
        }

        else if (error.response.status == "400") {
          let msg = error.response.data.detail + "\r\n";
          
          for (const err in error.response.data.errors) {
            msg += "\r\n" + err + ": " + error.response.data.errors[err];
          }
          
          commit(MutationTypes.RequestFailure, msg);
          status.personalErrorMsg = msg;
        }

        else {
          commit(MutationTypes.RequestFailure, error.response.data.message);
          status.personalErrorMsg = error.response.data.message;
        } 
      })
      .finally(() => {
        status.personalLoading = false;
        commit(MutationTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdatePersonaldatenNameUndAdresseTerminal]( {commit, getters}, data: NameUndAdresseEditDataRequest): Promise<void> {
    const status = getters.status;

    status.personalLoading = true;
    status.personalErrorMsg = "";
    commit(MutationTypes.SetStatus, status);

    return ApiService.putPersonaldatenNameUndAdresseTerminal(data)
      .then(res => {
        const personaldaten: Personaldaten = res.data;

        commit(MutationTypes.PersonaldatenSuccess, personaldaten);
        sessionStorage.setItem('personaldaten', JSON.stringify(personaldaten));
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          commit(MutationTypes.RequestFailure, error.code + "\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.");
          status.personalErrorMsg = error.code + "\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.";
        }

        else if (error.response.status == "400") {
          let msg = error.response.data.detail + "\r\n";
          
          for (const err in error.response.data.errors) {
            msg += "\r\n" + err + ": " + error.response.data.errors[err];
          }
          
          // commit(MutationTypes.RequestFailure, msg);
          status.personalErrorMsg = msg;
        }

        else if (error.response.status == "404") {
          status.personalErrorMsg = error.response.data.detail
        }

        else {
          // commit(MutationTypes.RequestFailure, error.response.data.message);
          status.personalErrorMsg = error.response.data.message;
        } 
      })
      .finally(() => {
        status.personalLoading = false;
        commit(MutationTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateBankverbindung]( {commit, getters}, data: BankverbindungEditDataRequest): Promise<void> {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalLoading = true;
    commit(MutationTypes.SetStatus, status);

    return ApiService.putBankverbindung(data)
      .then(res => {
        status.personalLoading = false;
        status.personalErrorMsg = "";
        commit(MutationTypes.SetStatus, status);

        const personaldaten: Personaldaten = res.data;

        commit(MutationTypes.PersonaldatenSuccess, personaldaten);
        sessionStorage.setItem('personaldaten', JSON.stringify(personaldaten));
      })
      .catch(error => {
        status.personalLoading = false;
        
        if (error.code == "ERR_NETWORK") {
          commit(MutationTypes.RequestFailure, error.code + "\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.");
          status.personalErrorMsg = error.code + "\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.";
        }
        else if (error.response.status == "400") {
          let msg = error.response.data.detail + "\r\n";
          
          for (const err in error.response.data.errors) {
            msg += "\r\n" + err + ": " + error.response.data.errors[err];
          }
          
          commit(MutationTypes.RequestFailure, msg);
          status.personalErrorMsg = msg;
        }
        else {
          commit(MutationTypes.RequestFailure, error.response.data.message);
          status.personalErrorMsg = error.response.data.message;
        } 

        commit(MutationTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateKrankenkasse]( {commit, getters}, data): Promise<void> {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalLoading = true;
    commit(MutationTypes.SetStatus, status);

    return ApiService.putKrankenkasse(data)
      .then(res => {
        status.personalLoading = false;
        status.personalErrorMsg = "";
        commit(MutationTypes.SetStatus, status);

        const personaldaten: Personaldaten = res.data;

        commit(MutationTypes.PersonaldatenSuccess, personaldaten);
        sessionStorage.setItem('personaldaten', JSON.stringify(personaldaten));
      })
      .catch(error => {
        status.personalLoading = false;
        
        if (error.code == "ERR_NETWORK") {
          commit(MutationTypes.RequestFailure, error.code + "\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.");
          status.personalErrorMsg = error.code + "\r\nEs ist etwas schief gelaufen,\r\nbitte probieren Sie es später erneut.";
        }
        else if (error.response.status == "400") {
          let msg = error.response.data.detail + "\r\n";
          
          for (const err in error.response.data.errors) {
            msg += "\r\n" + err + ": " + error.response.data.errors[err];
          }
          
          commit(MutationTypes.RequestFailure, msg);
          status.personalErrorMsg = msg;
        }
        else {
          commit(MutationTypes.RequestFailure, error.response.data.message);
          status.personalErrorMsg = error.response.data.message;
        } 

        commit(MutationTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetProfilbild]( {commit, getters}, data) {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalProfilbildLoading = true;
    commit(MutationTypes.SetStatus, status);

    ApiService.getProfilbildDokument(data.archivtoken)
      .then(res => {
        const profilBildDokument: Dokumentart = res.data;
        const downloadProfilbildRequest = new ArchivDownloadCompRequest(data.archivmandant, data.archivname, profilBildDokument.dokumente[0].docid, profilBildDokument.dokumente[0].componente.compid);
        ArchivApiService.getCompFile(downloadProfilbildRequest)
        .then(resp => {
          status.personalErrorMsg = "";
          status.personalProfilbildLoading = false;
          commit(MutationTypes.SetStatus, status);

          const img: ArchivComponenteFile = resp.data;
          commit(MutationTypes.GetProfilbildSuccess, img)
          sessionStorage.setItem('profilbild', JSON.stringify(img));
        })
        .catch(error => {
          status.personalProfilbildLoading = false;
          status.personalErrorMsg = error.response;
          commit(MutationTypes.SetStatus, status);
          sessionStorage.removeItem('profilbild');
        })
      })
      .catch(error => {
        status.personalProfilbildLoading = false;
        status.personalErrorMsg = error.response;
        commit(MutationTypes.SetStatus, status);
        sessionStorage.removeItem('profilbild');
      })
  },

  async [ActionTypes.GetAzureLoginConfig] ({commit, getters}) {
    const status = getters.status;

    status.personalErrorMsg = "";
    status.personalLoginLoading = true;
    commit(MutationTypes.SetStatus, status);

    ApiService.getAzureLoginConfig()
      .then(res => {
        status.personalErrorMsg = "";
        status.personalLoginLoading = false;
        commit(MutationTypes.SetStatus, status);

      const azureconfig: AzureConfigEinstellung = res.data
      commit(MutationTypes.GetAzureLoginConfigSuccess, azureconfig)
      sessionStorage.setItem('azureconfig', JSON.stringify(azureconfig));
      })
      .catch(() => {
        status.personalLoginLoading = false;
        commit(MutationTypes.SetStatus, status);

        sessionStorage.removeItem('azureconfig');
      })
  },

  async [ActionTypes.SendDocAsMail] ({commit, getters}, data) {
    const status = getters.status;
    status.sendEmailErrorMsg = "";
    status.sendEmailLoading = true;
    commit(MutationTypes.SetStatus, status);

    return ApiService.sendDocAsEmail(data)
    .then(() => {
      status.sendEmailErrorMsg = "";
    })
    .catch(error => {
      console.log(error);
      if (error.response && error.response.data && error.response.data.status == 401) {
        status.sendEmailErrorMsg = error.response.data.title;
      }
      else if (error.response && error.response.data && error.response.data.status == 404) {
        status.sendEmailErrorMsg = error.response.data.detail;
      }
      else {
        status.sendEmailErrorMsg = "Es ist ein unbekannter Fehler aufgetreten";
      }
      // status.personalErrorMsg = error.response;
    })
    .finally(() => {
      status.sendEmailLoading = false;
      commit(MutationTypes.SetStatus, status);
    })
  },

  async [ActionTypes.SendMultipleDocsAsMail] ({commit, getters}, data) {
    const status = getters.status;
    status.sendEmailErrorMsg = "";
    status.sendEmailLoading = true;
    commit(MutationTypes.SetStatus, status);

    return ApiService.sendMultipleDocsAsEmail(data)
    .then(() => {
      status.sendEmailErrorMsg = "";
    })
    .catch(error => {
      console.log(error);
      if (error.response && error.response.data && (error.response.data.status == 404 || error.response.data.status == 401)) {
        status.sendEmailErrorMsg = error.response.data.detail;
      }
      else {
        status.sendEmailErrorMsg = "Es ist ein unbekannter Fehler aufgetreten";
      }
      // status.personalErrorMsg = error.response;
    })
    .finally(() => {
      status.sendEmailLoading = false;
      commit(MutationTypes.SetStatus, status);
    })
  },

  async [ActionTypes.GetMultipleDocsAsZip] ({commit, getters}, data) {
    const status = getters.status;
    status.getDocsErrorMsg = "";
    status.getDocsLoading = true;
    commit(MutationTypes.SetStatus, status);
    commit(MutationTypes.SetZipDownload, new Blob())
    
    
    return ApiService.getMultipleDocsAsZip(data)
    .then(res => {
      const blob = new Blob([res.data]);
      commit(MutationTypes.SetZipDownload, blob)
    })
    .catch(error => {
      if (error.response && error.response.data && (error.response.data.status == 404 || error.response.data.status == 401)) {
        status.getDocsErrorMsg = error.response.data.detail;
      }
      else {
        status.getDocsErrorMsg = "Es ist ein unbekannter Fehler aufgetreten";
      }
    })
    .finally(() => {
      status.getDocsLoading = false;
      commit(MutationTypes.SetStatus, status);
    })
  },
}