<template>
  <div class="mainContainer bg-secondary">
    <div class="toolbar">
      <div class="toolbarContainer bg-secondary border-bottom border-primary">
        <div v-if="pdfActionsAvailable" class="toolbarViewer d-flex justify-content-between align-items-center px-2">
          <div class="text-white me-4" :class="!options.showPagesOption ? 'd-none' : ''">
            <div class="btn-group btn-group-sm" role="group">
              <button type="button" class="btn btn-outline-light" :disabled="currentPage == 1" @click="previousPage()"><font-awesome-icon icon="chevron-up" /></button>
              <button type="button" class="btn btn-outline-light" :disabled="currentPage == totalPages" @click="nextPage()"><font-awesome-icon icon="chevron-down" /></button>
            </div>

            <input type="text" class="form-control form-control-sm d-inline page-input-width mx-1 text-end" :id="'scroll-to-page-input-' + docId" v-model="currentPage" @change="setCurrentPage(currentPage)"> von {{ totalPages }}
          </div>

          <div class="me-4" :class="!options.showScalingOption ? 'd-none' : ''">
            <div class="btn-group d-inline">
              <button class="btn btn-secondary" @click="zoomOut()">
                <font-awesome-icon icon="minus"/>
              </button>
              <button class="btn btn-secondary" @click="zoomIn()">
                <font-awesome-icon icon="plus" />
              </button>
            </div>
            <span class="text-white ms-2">{{ scale }}</span>
          </div>

          <div class="">
            <button class="btn btn-secondary" title="Unterschreiben" @click="showSignaturModal()" :class="!options.showSignaturOption ? 'd-none' : ''">
              <font-awesome-icon icon="pen"  class="p-1 fa-lg"/>
            </button>
            <button class="btn btn-secondary" title="Drucken" @click="printPDF()" :class="!options.showPrintOption ? 'd-none' : ''">
              <font-awesome-icon icon="print"  class="p-1 fa-lg"/>
            </button>
            <button class="btn btn-secondary" title="Herunterladen" @click="saveInFileSystem()" :class="!options.showDownloadOption ? 'd-none' : ''">
              <font-awesome-icon icon="file-download" class="p-1 fa-lg"/>
            </button>
            <button class="btn btn-secondary" title="An E-Mail senden" @click="sendAsEmail()" :class="!options.showEmailSendenOption ? 'd-none' : ''">
              <font-awesome-icon icon="envelope" class="p-1 fa-lg"/>
            </button>
          </div>

        </div>
      </div>
    </div>

    <div :id="'container-' + docId" class="viewerContainer bg-secondary"></div>
  </div>

    <!-- Signatur Modal -->
  <div
    class="modal fade p-0 signatur-modal"
    :id="'signaturModalRef' + docId"
    tabindex="-1"
    :aria-labelledby="'signaturModalRef' + docId"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="signaturModalRef"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary p-2">
          <div class="w-100 d-flex justify-content-between me-3">
            <h5 class="modal-title my-auto" :id="'signaturModalRefLabel' + docId">
              Hier unterschreiben:
            </h5>
          </div>
          <button
          type="button"
          class="btn-close"
          @click="closeSignaturModal()"
          ></button>
        </div>

        <div class="modal-body bg-dark" :id="'signaturModalRefBody' + docId">
          <div class="mx-auto my-auto">
            <SignaturCanvas @insert-signatur-img="insertSignaturImg" ref="signaturCanvasRef"/>
          </div>
        </div>

        <div class="modal-footer p-2">
          <div>
            <button type="button" class="btn btn-secondary" @click="closeSignaturModal()">Abbrechen</button>
            <button type="button" class="btn btn-primary ms-3" @click="triggerInsertSignatur()">Einfügen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, PropType } from 'vue';
  import { usePdfViewer } from './PDFViewer';
  import { PDFViewerOptions } from './PDFViewerClasses'
  import { Personaldaten } from '@/models/Personal/PersonalModels'

  import { Modal } from 'bootstrap';

  import SignaturCanvas from './SignaturCanvas.vue';

  export default defineComponent({
    name: 'PdfViewer',

    components: {
      SignaturCanvas
    },

    props: {
      options: {
        type: Object as PropType<PDFViewerOptions>,
        default: new PDFViewerOptions()
      },
      personaldaten : {
        type: Object as PropType<Personaldaten>,
        default: null
      },
      pdfUrl: {
        type: String,
        default: ""
      },
      docId: {
        type: String,
        default: "default"
      },
      pdfName: {
        type: String,
        default: ""
      },
      persdocid: {
        type: String,
        default: ""
      },
    },

    emits: ["sendDocAsEmail"],

    setup(props) {
      const pdfName = ref("");

      const signaturModalRef = ref();
      const closeSignaturModal = () => {
        Modal.getInstance(signaturModalRef.value)?.hide();
      }
      const showSignaturModal = () => {
        if (signaturModalRef.value) {
          removeEditor();
          (signaturCanvasRef.value as typeof SignaturCanvas).clearCanvas();
          new Modal(signaturModalRef.value).show();
        }
      }
      const signaturCanvasRef = ref();

      async function insertSignaturImg (data: any) {
        closeSignaturModal();
        addStampAnnotation(data);
      }

      const {
        pdfUrl,
        pdfActionsAvailable,
        formsData,
        persdocid,
        currentPage,
        totalPages,
        scale,
        viewerId,

        loadPdf,
        saveOrDownloadPDF,

        setAnnotationEditorMode,
        removeEditor,
        addStampAnnotation,
        // addTextAnnotation,
        
        setCurrentPage,
        nextPage,
        previousPage,

        zoomIn,
        zoomOut,
        clearViewer
      } = usePdfViewer(props.docId, showSignaturModal);

      function showPDF(pdfDocUrl: string, pdfDocName: string, data: any) {
        pdfActionsAvailable.value = false;
        pdfUrl.value = pdfDocUrl;
        viewerId.value = props.docId;
        formsData.value = data;

        pdfName.value = pdfDocName;

        persdocid.value = props.persdocid;

        loadPdf().then(() => {
          pdfActionsAvailable.value = true;
        });
      }



      return {
        pdfActionsAvailable,
        pdfName,
        showPDF,

        currentPage,
        totalPages,
        scale,

        loadPdf,
        setCurrentPage,

        signaturModalRef,
        closeSignaturModal,
        showSignaturModal,
        signaturCanvasRef,
        insertSignaturImg,

        setAnnotationEditorMode,
        removeEditor,
        addStampAnnotation,

        saveOrDownloadPDF,

        nextPage,
        previousPage,

        zoomIn,
        zoomOut,

        clearViewer
      };
    },

    data () {
      return {
        numPages: 0,
        pdfloading: false,
        currentScale: 0,
      }
    },

    methods: {
      transformDate(datum: Date): string {
        if (datum == null) {
          return ""
        }

        const date: Date = new Date(datum.toString());

        return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
      },

      async saveInFileSystem() {
        const blobUrl = await this.saveOrDownloadPDF()

        const a = document.createElement("a");
        if (!a.click) {
          throw new Error('DownloadManager: "a.click()" is not supported.');
        }
        a.href = blobUrl;
        a.target = "_parent";
        if ("download" in a) {
          a.download = this.pdfName;
        }
        (document.body || document.documentElement).append(a);
        a.click();
        a.remove();
      },

      async printPDF() {
        const blobUrl = await this.saveOrDownloadPDF();
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.src = blobUrl

        const viewer = document.getElementById("viewer-" + this.docId) as HTMLDivElement;
        viewer.appendChild(iframe)

        iframe.onload = () => {
          iframe.contentWindow?.print()
        }
      },

      async sendAsEmail () {
        this.$emit("sendDocAsEmail");
      },

      triggerInsertSignatur() {
        if (this.signaturCanvasRef) {
          (this.signaturCanvasRef as typeof SignaturCanvas).extractDrawingWithoutBackground();
        }
      },

    },

    // mounted() {
    //   this.showPDF("a", "b", {});
    // },
  });
</script>

<style>
.mainContainer {
    position: absolute;
    inset: 0;
    min-width: 350px;
}

.toolbar {
  position: relative;
  inset-inline: 0;
  z-index: 9999;
  cursor: default;
}

.toolbarContainer {
  position: relative;
  height: 45px;
}

.toolbarViewer {
  height: 45px;
}

.viewerContainer {
  overflow: auto;
  position: absolute;
  inset: 50px 0 0;
  outline: none;
}

.viewerContainer, .viewerContainer * {
  box-sizing: content-box;
}

/* .viewerContainer {
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 97%;
} */

.page-input-width{
  width: 50px !important;
}

.set-foreground {
  z-index: 1070 !important;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
  outline: 1px solid #F1A82C;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
    border-radius: 0;
}

.stampEditor {
  z-index: 20 !important;
}

.signatur-modal {
  /* .annotationEditorLayer .selectedEditor z-index: 100000, signatur-modal soll immer darüber sein daher 100001 */
  z-index: 100001 !important;
}

.editToolbar {
  background-color: transparent !important;
  border: none !important;
}

button.signatur-accept::before {
  content: "\2713"; /* Unicode for check mark */
  /* color: green; */
  font-size: 1.2em;
  margin-right: 5px;
}

button.signatur-delete::before {
  content: "\2716"; /* Unicode for FontAwesome trash icon */
  font-size: 1.2em;
  margin-right: 5px;
}

/* button.signatur-btn::before { */
  /* content: "\270E"; Unicode for pen icon */
  /* font-size: 1.5em; */
  /* font-weight: bold; */
  /* display: inline-block; Necessary for transform to work on pseudo-elements */
  /* transform: rotate(90deg); Rotates the icon 180 degrees */
/* } */

/* Custom scrollbar styling */
/* Webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Firefox */
.pdfViewer {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
</style>
