import {ArchivDownloadCompRequest} from '../models/Archiv/ArchivModels';
import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './ArchivInterceptors';
import { RefreshLoginRequest, LogoutRequest } from "@/models/Personal/PersonalModels";

/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.archivapi.apiUrl;
const API_KEY = config.archivapi.apiKey;


const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));



export default {
  refreshLogin(data: RefreshLoginRequest) {
    return apiClient.post("/login/refresh", data)
  },

  archivLogout(data: LogoutRequest) {
    return apiClient.post("/logout", data)
  },

  getCompFile(data: ArchivDownloadCompRequest): Promise<AxiosResponse> {
    return apiClient.get(`/archiv4comp/getfile?archivmandant=${data.archivmandant}&archivname=${data.archivname}&docguid=${data.docguid}&compguid=${data.compguid}`);
  }
}