<template>
<!-- Name und Adresse Edit Modal -->
<div
  class="modal fade"
  :id="'nameUndAdresseEditModal' + index"
  tabindex="-1"
  aria-labelledby="nameUndAdresseEditModal"
  aria-hidden="true"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  ref="nameUndAdresseEditModalRef"
>
  <div class="modal-dialog  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="nameUndAdresseEditModalLabel">
          Edit Name und Address
        </h5>
        <button
          type="button"
          class="btn-close"
          @click="closeModal()"
        ></button>
      </div>

      <div class="modal-body" v-if="loading">
        <LoadingSpinner />
      </div>

      <div class="modal-body" v-if="!loading">

        <div class="my-3 text-center text-danger">
          <p class="lead fw-bold">Please pay attention to capitalization in all details.</p>
        </div>

        <div class="mb-2">
          <label class="form-label" :for="'titel-' + index">Titel</label>
          <input
            type="text"
            class="form-control"
            :id="'titel-' + index"
            v-model="titel"
            />
        </div>

        <div class="row mb-5">
            <div class="col">
              <div>
                <label :for="'vorname-' + index" class="form-label">First name</label>
                <input
                  type="text"
                  class="form-control"
                  :id="'vorname-' + index"
                  v-model="vorname"
                />
              </div>
            </div>
            <div class="col">
              <div>
                <label class="form-label" :for="'name-' + index">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  :id="'name-' + index"
                  v-model="name"
                />
              </div>
            </div>
          </div>


        <div class="row mb-2">
          <!-- <div class="col-8"> -->
          <div class="col">
            <div>
              <label class="form-label" :for="'strasse-' + index">Street</label>
              <input
                type="text"
                class="form-control"
                :id="'strasse-' + index"
                v-model="strasse"
              />
            </div>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-4">
            <label class="form-label" :for="'postleitzahl-' + index">Zip Code</label>
            <input
              type="text"
              class="form-control"
              :id="'postleitzahl-' + index"
              v-model="plz"
            />
          </div>
          <div class="col-8">
            <label class="form-label" :for="'wohnort-' + index">City</label>
            <input
              type="text"
              class="form-control"
              :id="'wohnort-' + index"
              v-model="ort"
            />
          </div>
        </div>


        <div class="mb-3">
          <label :for="'message-' + index" class="form-label">Message to HR</label>
          <textarea class="form-control" :id="'message-' + index" rows="4" v-model="message"></textarea>
        </div>

        <div class="mb-3" v-if="!terminalVersion">
          <label :for="'fileupload-' + index" class="form-label">Attach a file (optional)</label>
          <input type="file" class="form-control" :id="'fileupload-' + index" accept=".pdf,.PDF"  @change="handleFileUpload"/>
        </div>

        <div class="mb-3" v-if="terminalVersion">
          <p class="mb-2">Attach a file (optional)</p>
          <button class="btn btn-primary" @click="createScanMessage()">Scan document</button>
        </div>

        <hr>

        <p>
          <strong>Email address used to confirm the changes and questions from the HR department.</strong>
        </p>

        <div class="mb-3">
          <label class="form-label" :id="'best-email-' + index">Response E-Mail Address</label>
          <input
            type="text"
            class="form-control"
            :id="'best-email-' + index"
            v-model="responseEmailAdresse"
          />
        </div>

        <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
          <pre>{{errorMessage}}</pre>
        </div>

      </div>  

      <div class="modal-footer" v-if="!loading">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal()"
            >
            Cancel
          </button>
          
          <!-- <div class="btn-group" role="group" aria-label="Änderungsmitteilung...">
            <button class="btn btn-outline-secondary" @click="savePDFChangeRequest()">Save as PDF</button>
            <button class="btn btn-outline-secondary" @click="printChangeRequest()">Print</button>
            <button class="btn btn-primary" @click="saveChanges()">Send</button>
          </div> -->
          <button class="btn btn-primary" @click="saveChanges()">Send</button>
        </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, computed, ref } from "vue";
import { Modal } from 'bootstrap';
// import { jsPDF } from 'jspdf';
// import printjs from 'print-js';

import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { useStore } from '@/store'
import { ActionTypes as PersonalActionTypes } from "@/store/modules/Personal/actions";
import { ActionTypes as VorgangActionTypes } from "@/store/modules/Vorgang/actions";
import { ActionTypes as NachrichtenActionTypes } from "@/store/modules/Nachrichten/actions";
import { NameUndAdresseEditDataRequest } from "@/models/Personal/PersonalModels";
import {v4 as uuidv4 } from 'uuid'
import { DocAnHR, ScanAnHR } from "@/models/Vorgange/VorgangeModels";

export default defineComponent ({
name: "NameUndAdresseEdit",
components: {
  LoadingSpinner
},

props: {
  index: {
    type: Number,
    default: 0
  }
},

setup () {
  const MAX_FILE_SIZE = 10 * 1024 * 1024

  const store = useStore();
  const terminalVersion = computed(() => store.getters.terminalVersion);
  const loading = computed(() => store.getters.status.personalLoading)
  const status = computed(() => store.getters.status)

  const file = ref();

  const personaldaten = computed(() => store.getters.personaldaten);

  const state = reactive({
    titel: personaldaten.value.titel,
    vorname: personaldaten.value.vorname,
    name: personaldaten.value.name,
    strasse: personaldaten.value.strasse,
    // strasse: strasseArray.slice(0, strasseArray.length - 1).toString(),
    // hausnummer: strasseArray[strasseArray.length - 1],
    plz: personaldaten.value.plz,
    ort: personaldaten.value.ort,
    land: personaldaten.value.land,
    message: "",
    responseEmailAdresse: personaldaten.value.email,
    postfachartid: 1,
    guid: "",
    // file: null,
    scanMsgCreated: false,
    showError: false
  })

  let errorMessage = ref("");

  const resetForm = () => {
      state.titel = personaldaten.value.titel,
      state.vorname = personaldaten.value.vorname,
      state.name = personaldaten.value.name,
      state.strasse = personaldaten.value.strasse,
      state.plz = personaldaten.value.plz,
      state.ort = personaldaten.value.ort,
      state.land = personaldaten.value.land,
      state.message = "",
      state.responseEmailAdresse = personaldaten.value.email,
      state.postfachartid = 1
      errorMessage.value = "";
      state.guid = ""
      // state.file = null
      file.value = null
      state.scanMsgCreated  = terminalVersion.value ? false : true;
      state.showError = false
    }

  const nameUndAdresseEditModalRef = ref();
  const closeModal = () => {
    resetForm();
    Modal.getInstance(nameUndAdresseEditModalRef.value)?.hide();
  }
  const showModal = () => {
    if (nameUndAdresseEditModalRef.value) {
      state.guid = uuidv4()
      new Modal(nameUndAdresseEditModalRef.value).show();
    }
  }


  return {
    store,
    loading,
    status,
    terminalVersion,
    personaldaten,
    ...toRefs(state),
    errorMessage,
    nameUndAdresseEditModalRef,
    closeModal,
    showModal,
    file,
    MAX_FILE_SIZE
  }
},

methods: {
  handleFileUpload (event: Event) {
    const inputElement = event.target as HTMLInputElement
    const file = inputElement.files![0]

    if (file) {
      this.file = file
    }

  },

  startPersonalScan() {
    if (window.hasOwnProperty("siteKiosk")) {
      (window as any).siteKiosk?.eventHub?.trigger('startPersonalscan', `${this.guid}`)
    }
    else {
      console.log("SiteKiosk defined: " + window.hasOwnProperty("siteKiosk"))
    }
  },

  async createScanMessage () {
      this.errorMessage = "";

      const data: ScanAnHR = {
        betreff: "Namens- und Adressänderung mitteilen",
        message: this.message,
        responseEmailAdresse: this.responseEmailAdresse,
        vorgang: this.guid
      }
      
      this.store.dispatch(VorgangActionTypes.SendScanAnHRCreate, data)
      .then(() => {
        if (this.status.vorgangErrorMsg != "") {
          this.showError = true
          this.errorMessage = this.status.vorgangErrorMsg;
        }
        else {
          this.scanMsgCreated = true
          this.startPersonalScan()
        }
      });
    },

  async saveChanges () {
    this.errorMessage = "";

    const data: NameUndAdresseEditDataRequest = {
      titel: this.titel,
      vorname: this.vorname,
      name: this.name,
      strasse: this.strasse,
      // hausnummer: this.hausnummer,
      plz: this.plz,
      ort: this.ort,
      land: this.land,
      message: this.message,
      responseEmailAdresse: this.responseEmailAdresse,
      postfachartid: 1,
      docFile: null,
      vorgang: this.guid
    }

    if (this.terminalVersion && this.scanMsgCreated) {
        this.store.dispatch(PersonalActionTypes.UpdatePersonaldatenNameUndAdresseTerminal, data)
        .then(() => {
          if (this.status.personalErrorMsg != "") {
            this.errorMessage = this.status.personalErrorMsg;
          }
          else {
            this.closeModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
          }
        });
      }

    else {
      this.store.dispatch(PersonalActionTypes.UpdatePersonaldatenNameUndAdresse, data)
        .then(() => {
          
          if (this.status.personalErrorMsg != "") {
            this.errorMessage = this.status.personalErrorMsg;
          }
          else {
            this.closeModal();
            this.store.dispatch(NachrichtenActionTypes.GetPostfachNachrichten, undefined)
          }
        });
    }
  },

  checkInputFirst(e: KeyboardEvent) {
    let notAllowedChars = [';', ','];
    if (notAllowedChars.includes(e.key)) {
      e.preventDefault();
    }
  },

  setInputEventListener () {
    const titel = document.getElementById("titel") as HTMLElement | null
    titel?.addEventListener("keydown", this.checkInputFirst);
    
    const vorname = document.getElementById("vorname") as HTMLElement | null
    vorname?.addEventListener("keydown", this.checkInputFirst);
    
    const name = document.getElementById("name") as HTMLElement | null
    name?.addEventListener("keydown", this.checkInputFirst);
    
    const strasse = document.getElementById("strasse") as HTMLElement | null
    strasse?.addEventListener("keydown", this.checkInputFirst);
    
    const plz = document.getElementById("postleitzahl") as HTMLElement | null
    plz?.addEventListener("keydown", this.checkInputFirst);
    
    const ort = document.getElementById("wohnort") as HTMLElement | null
    ort?.addEventListener("keydown", this.checkInputFirst);
  }

  // savePDFChangeRequest () {
  //     const doc = new jsPDF();

  //     const text = this.createText();

  //     doc.text(text, 15, 20);

  //     doc.save("Namens- und Adressänderungsantrag.pdf");
  //   },

  //   printChangeRequest () {
  //     const doc = new jsPDF();

  //     const text = this.createText();

  //     doc.text(text, 15, 20);
      
  //     const data = doc.output('blob');
  //     const blobUrl = URL.createObjectURL(data);
  //     printjs(blobUrl);
      
  //   },

  //   createText (): string {
  //     let text = "";
  //     text += `Betreff: Namens- und Adressänderung\r\n\r\n`;

  //     text += `Guid: ${this.personaldaten.guid != null ? this.personaldaten.guid : ""}\r\n`;
  //     text += `Mandant: ${this.personaldaten.mandant != null ? this.personaldaten.mandant : ""}\r\n`;
  //     text += `Abrechnungskreis: ${this.personaldaten.abrkreis != null ? this.personaldaten.abrkreis : ""}\r\n`;
  //     text += `Personalnummer: ${this.personaldaten.persnr != null ? this.personaldaten.persnr : ""}\r\n\r\n`;

  //     text += 'Alte Daten:\r\n';
  //     text += `Titel: ${this.personaldaten.titel != null ? this.personaldaten.titel : ""}\r\n`;
  //     text += `Name: ${this.personaldaten.name != null ? this.personaldaten.name : ""}\r\n`;
  //     text += `Vorname: ${this.personaldaten.vorname != null ? this.personaldaten.vorname : ""}\r\n`;
  //     text += `Straße: ${this.personaldaten.strasse != null ? this.personaldaten.strasse : ""}\r\n`;
  //     text += `PLZ: ${this.personaldaten.plz != null ? this.personaldaten.plz : ""}\r\n`;
  //     text += `Ort: ${this.personaldaten.ort != null ? this.personaldaten.ort : ""}\r\n`;
  //     text += `Land: ${this.personaldaten.land != null ? this.personaldaten.land : ""}\r\n\r\n`;

  //     text += 'Neue Daten:\r\n';
  //     text += `Titel: ${this.titel != null ? this.titel : ""}\r\n`;
  //     text += `Name: ${this.name != null ? this.name : ""}\r\n`;
  //     text += `Vorname: ${this.vorname != null ? this.vorname : ""}\r\n`;
  //     text += `Straße: ${this.strasse != null ? this.strasse : ""}\r\n`;
  //     text += `PLZ: ${this.plz != null ? this.plz : ""}\r\n`;
  //     text += `Ort: ${this.ort != null ? this.ort : ""}\r\n`;
  //     text += `Land: ${this.land != null ? this.land : ""}\r\n\r\n`;

  //     text += `Antwort E-Mail Adresse: ${this.responseEmailAdresse != null ? this.responseEmailAdresse : ""}\r\n\r\n`

  //     text += `Nachricht:\r\n${this.message != null ? this.message : ""}\r\n\r\n`;

  //     // text += `Mit freundlichen Grüßen,\r\n\r\n\r\n`;
  //     // text += `________________________\r\n${this.vorname} ${this.name}\r\n\r\n`;
  //     return text;
  //   }
  },
  
  mounted () {
    this.scanMsgCreated = this.terminalVersion ? false : true;
    this.setInputEventListener()
  }

})
</script>

<style scoped>
.vorgang:hover {
  color: #d99728 !important;
  cursor: pointer;
}
</style>