import {PostfachNachrichtUpdateStatus} from '../models/Nachrichten/NachrichtenModels';
import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './NachrichtApiInterceptors';


/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.nachrichtapi.apiUrl;
const API_KEY = config.nachrichtapi.apiKey;


const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));



export default {
  getBenutzerNachrichten(): Promise<AxiosResponse> {
    return apiClient.get('/postfach/all/guid');
  },

  changePostfachNachrichtStatus(data: PostfachNachrichtUpdateStatus): Promise<AxiosResponse> {
    return apiClient.put(`/postfach`, data)
  }
}