import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { Actions as PersonalActions, ActionTypes as PersonalActionTypes} from "../Personal/actions"

import { RootState } from '@/store';

import { State } from "./state";
import ApiService from "@/services/ApiService";
import ArchivApiService from "@/services/ArchivApiService";

import { LogoutRequest, RefreshLoginRequest } from "@/models/Personal/PersonalModels";
import { ArchivAuth, ArchivDonwloadCompData, GetFolderDocsRequest, ArchivDokument, Folder, Dokumentart, SetFolderDocs, ArchivDownloadCompRequest, ArchivComponente, FolderDocsSearchParams } from "@/models/Archiv/ArchivModels";

const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 
const PAGESIZE: number = config.ordnerPageSize

export enum ActionTypes {
  ArchivLogin = "ARCHIVLOGIN",
  ArchivRefreshLogin = "ARCHIVREFRESHLOGIN",
  ArchivLogout = "ARCHIVLOGOUT",
  ClearArchivState = "CLEARARCHIVSTATE",
  GetDokumentarten = "GETDOKUMENTARTEN",
  ArchivDownloadComponente = "ARCHIVDOWNLOADCOMPONENT",
  ArchivClearComponentenCache = "ARCHIVCLEARECOMPONENTENCACHE",
  GetMitarbeiterFolder = "GETMITARBIETERFOLDER",
  GetFolderDocs = "GETFOLDERDOCS",
  LoadFolderDocsPage = "LOADFOLDERDOCSPAGE",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.ArchivLogin](context: ActionArguments): Promise<void>,
  [ActionTypes.ArchivRefreshLogin](context: ActionArguments): Promise<void>,
  [ActionTypes.ArchivLogout](context: ActionArguments): Promise<void>,
  [ActionTypes.ClearArchivState](context: ActionArguments): void
  [ActionTypes.GetDokumentarten](context: ActionArguments, archivtoken: string): void
  [ActionTypes.ArchivDownloadComponente](context: ActionArguments, data: ArchivDonwloadCompData): Promise<void>
  [ActionTypes.ArchivClearComponentenCache](context: ActionArguments): void
  [ActionTypes.GetMitarbeiterFolder](context: ActionArguments): void
  [ActionTypes.GetFolderDocs](context: ActionArguments, getFolderDocsRequest: GetFolderDocsRequest): void
  [ActionTypes.LoadFolderDocsPage](context: ActionArguments, getFolderDocsRequest: GetFolderDocsRequest): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.ArchivLogin]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;

    status.archivLoading = true
    dispatch(PersonalActionTypes.SetStatus, status);

    return ApiService.archivLogin()
      .then(res => {
        status.archivLoading = false
        status.archivLoggedIn = true
        dispatch(PersonalActionTypes.SetStatus, status);

        const archivUser: ArchivAuth = res.data;

        commit(MutationTypes.ArchivLoginSuccess, archivUser);
        sessionStorage.setItem('archivUser', JSON.stringify(archivUser));
      })
      .catch(error => {
        status.archivLoading = false
        status.archivErrorMsg = error.response.data.title;
        dispatch(PersonalActionTypes.SetStatus, status);
        sessionStorage.removeItem('archivUser');
      })
  },

  async [ActionTypes.ArchivRefreshLogin]({commit, dispatch, rootGetters}) {
    const request = new RefreshLoginRequest(rootGetters.archivUser.refreshToken);
    const res = await ArchivApiService.refreshLogin(request);

    const result: ArchivAuth = res.data;
    commit(MutationTypes.ArchivRefreshLoginSuccess, result)
    sessionStorage.setItem("archivUser", JSON.stringify(result));

    return
  },

  async [ActionTypes.ArchivLogout]({commit, dispatch, rootGetters}) {
    const request = new LogoutRequest(rootGetters.archivUser.refreshToken);
    return ArchivApiService.archivLogout(request)
    .then(() => {
      commit(MutationTypes.ClearArchivState, undefined)
      sessionStorage.removeItem("archivUser");
    })
    .catch(() => {
      commit(MutationTypes.ClearArchivState, undefined)
      sessionStorage.removeItem("archivUser");
    })
  },

  [ActionTypes.ClearArchivState]({commit}) {
    commit(MutationTypes.ClearArchivState, undefined)
  },

  async [ActionTypes.GetDokumentarten]( {commit, dispatch, rootGetters}, archivtoken: string) {
    const status = rootGetters.status;

    status.archivDokumentartenLoading = true
    dispatch(PersonalActionTypes.SetStatus, status);


    ApiService.getDokumentartenUndDokumente(archivtoken)
      .then(res => {
        status.archivDokumentartenLoading = false
        dispatch(PersonalActionTypes.SetStatus, status);

        const dokumentarten: Array<Dokumentart> = res.data;

        commit(MutationTypes.DokumentartenSuccess, dokumentarten);
        sessionStorage.setItem('dokumentarten', JSON.stringify(dokumentarten));
      })
      .catch(error => {
        status.archivDokumentartenLoading = false
        status.archivErrorMsg = error.response.data.message;
        dispatch(PersonalActionTypes.SetStatus, status);
        sessionStorage.removeItem('dokumentarten');
      })
  },

  async [ActionTypes.ArchivDownloadComponente]({commit, dispatch, rootGetters}, data){
    const status = rootGetters.status;

    status.archivCompLoading = true
    dispatch(PersonalActionTypes.SetStatus, status);
    
    const requestData: ArchivDownloadCompRequest = new ArchivDownloadCompRequest(data.archivmandant, data.archivname, data.componente.docid, data.componente.compid);
    // const componente: ArchivComponente = data.componente;
    const componente: ArchivComponente = JSON.parse(JSON.stringify(data.componente));

    return ArchivApiService.getCompFile(requestData)
      .then(resp => {
        status.archivCompLoading = false
        dispatch(PersonalActionTypes.SetStatus, status);

        componente.file = resp.data

        commit(MutationTypes.ComponentenSuccess, componente);
      })
      .catch(error => {
        console.log(error);
        status.archivCompLoading = false
        status.archivErrorMsg = error.response.data.message
        dispatch(PersonalActionTypes.SetStatus, status);
      })
  },

  [ActionTypes.ArchivClearComponentenCache]({commit}){
    commit(MutationTypes.ArchivClearComponentenCache, undefined);
  },

  async [ActionTypes.GetMitarbeiterFolder]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;

    status.archivFolderLoading = true
    dispatch(PersonalActionTypes.SetStatus, status);

    ApiService.getMitarbeiterFolder()
      .then(res => {
        status.archivFolderLoading = false
        dispatch(PersonalActionTypes.SetStatus, status);

        const folder: Array<Folder> = res.data;

        commit(MutationTypes.GetMitarbeiterFolderSuccess, folder);
        sessionStorage.setItem('folder', JSON.stringify(folder));
      })
      .catch(error => {
        status.archivFolderLoading = false
        status.archivErrorMsg = error.response.data.message
        dispatch(PersonalActionTypes.SetStatus, status);
        sessionStorage.removeItem('folder');
      })
  },

  async [ActionTypes.GetFolderDocs]({commit, dispatch, rootGetters}, getFolderDocsRequest) {
    const status = rootGetters.status;
    status.archivErrorMsg = ""
    status.archivFolderDocsLoading = true
    dispatch(PersonalActionTypes.SetStatus, status);

    const dokumente = new Array<ArchivDokument>();
    const data = new SetFolderDocs(getFolderDocsRequest.folderid, dokumente);
    commit(MutationTypes.GetFolderDocsSuccess, data);

    ApiService.getFolderDocs(getFolderDocsRequest)
      .then(res => {
        const dokumente: Array<ArchivDokument> = res.data;
        const data = new SetFolderDocs(getFolderDocsRequest.folderid, dokumente);
        commit(MutationTypes.GetFolderDocsSuccess, data);

        const allDocsLoaded = getFolderDocsRequest.searchString == "" ? false : true
        const page = getFolderDocsRequest.searchString == "" ? getFolderDocsRequest.page : Math.floor(dokumente.length / PAGESIZE)

        const folderData = new FolderDocsSearchParams(getFolderDocsRequest.folderid, getFolderDocsRequest.searchString, page, allDocsLoaded)
        commit(MutationTypes.AddOrUpdateFolderData, folderData)
      })
      .catch(error => {
        status.archivErrorMsg = error.response.data.message
      })
      .finally(() => {
        status.archivFolderDocsLoading = false
        dispatch(PersonalActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.LoadFolderDocsPage]({commit, dispatch, rootGetters}, getFolderDocsRequest) {
    const status = rootGetters.status;
    status.archivFolderDocsLoading = true
    status.archivErrorMsg = "";
    dispatch(PersonalActionTypes.SetStatus, status);

    ApiService.getFolderDocs(getFolderDocsRequest)
      .then(res => {
        const dokumente: Array<ArchivDokument> = res.data;
        const data = new SetFolderDocs(getFolderDocsRequest.folderid, dokumente);
        commit(MutationTypes.AddFolderDocs, data);
        
        const folderData = new FolderDocsSearchParams(getFolderDocsRequest.folderid, getFolderDocsRequest.searchString, getFolderDocsRequest.page, false)
        if (getFolderDocsRequest.page != null && getFolderDocsRequest.pageSize != null && dokumente.length < getFolderDocsRequest.pageSize) {
          folderData.page = getFolderDocsRequest.page - 1;
          folderData.allDocsLoaded = true;
        }
        // handle and save when no more documents 
        commit(MutationTypes.AddOrUpdateFolderData, folderData)
      })
      .catch(error => {
        status.archivErrorMsg = error.response.data.message
      })
      .finally(() => {
        status.archivFolderDocsLoading = false
        dispatch(PersonalActionTypes.SetStatus, status);
      })
  }
}