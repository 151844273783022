export class ArchivAuth {
  userID: string
  benutzer: string
  token: string
  refreshToken: string

  constructor () {
    this.userID = "";
    this.benutzer = "";
    this.token = "";
    this.refreshToken = "";
  }
}

export class ArchivDokument {
  id: number;
  docid: string;
  ordid: string;
  titel: string;
  titel_1: string;
  titel_2: string;
  titel_3: string;
  titel_4: string;
  titel_5: string;
  adate: Date;
  mdate: Date;
  // vdate: Date;
  // edate: Date;
  ddate: Date;
  alversion: string;
  protection: string;
  barcode: string;
  autor: string;
  // status: number;
  // cryid: number;
  // keyid: number;
  componente: ArchivComponente

  constructor () {
    this.id = 0,
    this.docid = "",
    this.ordid = "",
    this.titel = "",
    this.titel_1 = "",
    this.titel_2 = "",
    this.titel_3 = "",
    this.titel_4 = "",
    this.titel_5 = "",
    this.adate = new Date(),
    this.mdate = new Date(),
    // this.vdate = new Date(),
    // this.edate = new Date(),
    this.ddate = new Date(),
    this.alversion = "",
    this.protection = "",
    this.barcode = "",
    this.autor = "",
    // this.status = 0,
    // this.cryid = 0,
    // this.keyid = 0
    this.componente = new ArchivComponente()
  }
}

export interface Dokumentart {
  id: number;
  titel: string;
  dokumente: Array<ArchivDokument>
}

export class GetFolderDocsRequest {
  archivtoken: string;
  folderid: number;
  page: number | null;
  pageSize: number | null;
  searchString: string

  constructor (archivtoken: string, folderid: number, searchString: string, page: number | null = null, pageSize: number | null = null) {
    this.archivtoken = archivtoken;
    this.folderid = folderid;
    this.searchString = searchString;
    this.page = page;
    this.pageSize = pageSize;
  }
}

export class Folder {
  id: number;
  foldertitle: string;
  foldertitleDe: string
  foldertitleEn: string
  foldermenu: string;
  foldermenuDe: string
  foldermenuEn: string
  druckenmitarbeiter: number
  dokumente: Array<ArchivDokument>;

  constructor () {
    this.id = 0;
    this.foldertitle = "";
    this.foldermenu = "";
    this.foldertitleDe = "";
    this.foldertitleEn = "";
    this.foldermenuDe = "";
    this.foldermenuEn = "";
    this.druckenmitarbeiter = 0;
    this.dokumente = new Array<ArchivDokument>();
  }
}

export class FolderDocsSearchParams {
  folderid: number;
  searchString: string;
  page: number | null;
  allDocsLoaded: boolean

  constructor(folderid: number, searchString: string, page: number | null = null, allDocsLoaded: boolean = false) {
    this.folderid = folderid;
    this.searchString = searchString;
    this.page = page;
    this.allDocsLoaded = allDocsLoaded;
  }
}

export class SetFolderDocs {
  folderid: number;
  dokumente: Array<ArchivDokument>

  constructor (folderid: number, dokumente: Array<ArchivDokument>) {
    this.folderid = folderid;
    this.dokumente = dokumente;
  }
}

export class ArchivDonwloadCompData {
  archivname: string;
  archivmandant: string;
  componente: ArchivComponente;

  constructor () {
    this.archivname = "",
    this.archivmandant = "",
    this.componente = new ArchivComponente()
  }
}

export class ArchivDownloadCompRequest {
  archivname: string;
  archivmandant: string;
  docguid: string;
  compguid: string;

  constructor (archivmandant: string, archivname: string, docguid: string, compguid: string) {
    this.archivname = archivname,
    this.archivmandant = archivmandant,
    this.docguid = docguid,
    this.compguid = compguid
  }
}

export class ArchivComponenteFile {
  filesize: number;
  filecontentAsBase64: string;

  constructor() {
    this.filesize = 0,
    this.filecontentAsBase64 = ""
  }
}

export class ArchivComponente {
  id: number;
  // sortid: number;
  compid: string;
  docid: string;
  filename: string;
  // extvolname: string;
  // extfilename: string;
  contenttype: string;
  // charset: string;
  adate: Date;
  mdate: Date;
  // vdate: Date;
  // edate: Date;
  // ddate: Date;
  // alversion: string;
  // version: string;
  // barcode: string;
  titel: string;
  autor: string;
  // such_0: string;
  // such_1: string;
  // such_2: string;
  // such_3: string;
  // such_4: string;
  // such_5: string;
  // such_6: string;
  // such_7: string;
  // such_8: string;
  // such_9: string;
  // status: number;
  fileversion: number;
  // cryid: number;
  // keyid: number;
  file: ArchivComponenteFile;

  constructor() {
    this.id = 0,
    // this.sortid = 0,
    this.compid = "",
    this.docid = "",
    this.filename = "",
    // this.extvolname = "",
    // this.extfilename = "",
    this.contenttype = "",
    // this.charset = "",
    this.adate = new Date(),
    this.mdate = new Date(),
    // this.vdate = new Date(),
    // this.edate = new Date(),
    // this.ddate = new Date(),
    // this.alversion = "",
    // this.version = "",
    // this.barcode = "",
    this.titel = "",
    this.autor = "",
    // this.such_0 = "",
    // this.such_1 = "",
    // this.such_2 = "",
    // this.such_3 = "",
    // this.such_4 = "",
    // this.such_5 = "",
    // this.such_6 = "",
    // this.such_7 = "",
    // this.such_8 = "",
    // this.such_9 = "",
    // this.status = 0,
    this.fileversion = 0,
    // this.cryid = 0,
    // this.keyid = 0,
    this.file = new ArchivComponenteFile()
  }
}